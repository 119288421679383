.technology_page_container {
  .first_paragraph {
    width: 45%;
    padding: 2% 0 0 7%;
  }
  .hero_image_container {
    width: 100%;
    position: relative;
    top: -75px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .second_paragraph {
    padding: 2% 7%;
  }
  .tech_slogans {
    padding: 2% 7% 7%;
    font-size: 3.75vw;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    .blue_text {
      color: #31465e;
    }
    .gray_text {
      color: #6e6e6e;
      margin-right: 10px;
      font-weight: lighter;
    }
  }
  .our_sass_offerings_section {
    background-color: #f5f5f5;
    padding: 3% 7% 7%;
    .sass_info {
      padding: 4% 0;
    }
  }
  .hero_image_bottom {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .technology_page_container {
    .first_paragraph {
      width: 100%;
      padding: 2% 7%;
    }
    .hero_image_container {
      top: 0px;
    }
    .tech_slogans {
      font-size: 19px;
      width: 100%;
      .slogan_row {
        flex-wrap: wrap;
        .blue_text {
          margin-right: 0px;
        }
        .gray_text {
          margin-right: 0px;
        }
      }
    }
    .our_sass_offerings_section {
      background-color: #f5f5f5;
      padding: 2% 7%;
      .sass_info {
        padding: 2% 0;
      }
    }
    .hero_image_bottom {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
