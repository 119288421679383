.employee_image_container {
  position: relative;
  text-align: center;
  background-image: linear-gradient(
    to bottom,
    rgba(82, 82, 82, 0),
    #101010 121%
  );
  img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: -1;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  .employee_caption {
    color: white;
    position: absolute;
    bottom: 1%;
    font-size: 15px;
    line-height: 1.2;
    padding: 5%;
    .employee_name {
      font-weight: bold;
    }
    .employee_position {
      font-style: italic;
    }
  }
}
