@mixin line-separator {
  width: 100%;
  height: 3px;
  background-color: #191919;
}
.leadership_page_container {
  .leader_content {
    padding: 5% 35%;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    .leader_name {
      font-weight: bold;
      padding-bottom: 2%;
    }
    .leader_title {
      font-style: italic;
      padding-bottom: 2%;
    }
    .leader_image_container {
      img {
        width: 100%;
        height: auto;
      }
    }
    .leader_paragraph {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      padding: 10% 1% 5% 1%;
    }
    .line_separator {
      @include line-separator();
    }
  }
  .back_link_container {
    padding: 2% 7%;
    .line_separator {
      @include line-separator();
      background-color: #efefef;
    }
    .back_link {
      padding: 2% 0;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.5;
      letter-spacing: normal;
      text-align: left;
      a {
        color: #000000;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .leadership_page_container {
    .leader_content {
      padding: 5% 7%;
      font-size: 20px;
      line-height: 1.5;
    }
  }
}
