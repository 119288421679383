@mixin golden-line {
  width: 406px;
  height: 12px;
  background-color: #e9af61;
}
.digital_marketing_page_container {
  .funnel_container {
    padding: 4% 7% 7%;
    .image_container {
      width: 100%;
      padding-top: 8%;
      .funnel_mobile {
        display: none;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .our_offerings {
    background-color: #3e3e3e;
    padding: 0 7% 2% 7%;
    .golden_line {
      @include golden-line();
    }
    .info {
      display: flex;
      justify-content: space-between;
      .text {
        width: 55%;
        padding-right: 5%;
      }
      .icons {
        width: 50%;
        display: flex;
        justify-content: space-between;
        .icon_container {
          width: 17%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .paid_social {
    background: linear-gradient(180deg, #efefef 80%, #fff 0);
    padding: 0 7% 19%;
    .golden_line {
      @include golden-line();
    }
    .info {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .text {
        width: 75%;
        padding-right: 8%;
      }
      .image_container {
        width: 60%;
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
        .pen_image_container {
          position: absolute;
          top: 80%;
          left: 20%;
          width: 80%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .paid_search {
    background-color: #efefef;
    padding: 0px 7% 4%;
    .golden_line {
      @include golden-line();
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .digital_marketing_page_container {
    .funnel_container {
      .image_container {
        margin-top: 9%;
        .funnel_desktop {
          display: none;
        }
        .funnel_mobile {
          display: block;
        }
      }
    }
    .paid_social {
      padding: 0 7% 7%;
      .info {
        flex-direction: column;
        .text {
          width: 100%;
        }
        .image_container {
          width: 100%;
        }
      }
    }
    .our_offerings {
      padding: 0 7% 18% 7%;
      .info {
        flex-direction: column;
        .text {
          width: 100%;
        }
        .icons {
          width: 100%;
        }
      }
    }
  }
  .video_wrapper {
    margin: 15% 0px;
  }
}
