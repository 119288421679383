.news_tiles_container {
  padding: 7% 7% 0;
  display: flex;
  flex-wrap: wrap;
  .news_tile {
    width: 24%;
    margin-right: 1%;
    margin-bottom: 7%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .news_tiles_container {
    padding-top: 5%;
    justify-content: space-between;
    .news_tile {
      width: 48%;
      margin-right: 0;
      padding-top: 2%;
      padding-bottom: 2%;
    }
  }
}
