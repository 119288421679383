.subtitle_container {
  padding-left: 7%;
  font-size: 22px;
  line-height: 1.13;
  letter-spacing: 9px;
  font-weight: lighter;
  .subtitle_overline {
    width: 441px;
    height: 12px;
    background-color: #bf9058;
  }
}
