.leadership_container {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  min-height: 100%;
  .leadership_image_container {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .leadership_info {
    padding: 1%;
    height: 20vh;
    position: relative;
    .leadership_name {
      font-weight: bold;
    }
    .leadership_position {
      font-style: italic;
    }
    .leadership_more_info {
      width: 100%;
      position: absolute;
      bottom: 20%;
      padding-top: 10%;
      font-size: 16px;
      text-decoration: underline;
      display: flex;
      align-items: center;
      .icon_container {
        width: 5.5%;
        margin-right: 2%;
        img {
          width: 100%;
          height: auto;
        }
      }
      a {
        color: #e9af61;
      }
    }
    .add_padding {
      padding-top: 20%;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .leadership_container {
    font-size: 15px;
  }
}
