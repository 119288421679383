@mixin input-style($height) {
  width: 100%;
  height: $height;
  background-color: #efefef;
  border: none;
  margin: 0 1% 1%;
  padding: 2%;
}
.contact_page_container {
  .user_input_section {
    padding: 2% 7% 6%;
    .page_title {
      font-size: 34px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 4.12;
      letter-spacing: -0.85px;
      text-align: left;
      color: #000000;
    }
    .input_container form {
      letter-spacing: -0.4px;
      text-align: left;
      color: #000000;
      display: flex;
      justify-content: flex-start;
      .personal_input {
        width: 35%;
        height: 110px;
        input {
          width: 100%;
          min-height: 4vw;
          background-color: #efefef;
          border: none;
          margin: 0 1% 2.5%;
          padding: 3% 5%;
          font-size: 1.2vw;
          &:focus {
            outline: none !important;
            border-color: #e9af61;
            box-shadow: 0 0 5px #e9af61;
          }
        }
        ::placeholder {
          color: #666;
          font-family: inherit;
          font-size: inherit;
        }
      }
      .message_input {
        letter-spacing: -0.4px;
        width: 65%;
        height: 125px;
        .textarea {
          width: 100%;
          height: 100%;
          background-color: #efefef;
          border: none;
          margin: 0px 2% 0%;
          font-family: inherit;
          font-size: 1.2vw;
          letter-spacing: normal;
          padding: 3%;
          &:focus {
            outline: none !important;
            border-color: #e9af61;
            box-shadow: 0 0 5px #e9af61;
          }
        }
        ::placeholder {
          color: #666;
        }
      }
    }
    .button {
      margin-top: 2%;
    }
  }
  .info_section {
    display: flex;
    .info_image_container {
      width: 38%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .info_text_container {
      width: 62%;
      background-color: #f4f4f4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info_tile {
        width: 33%;
        padding: 2%;
        text-align: left;
        color: #000000;
        .info_title {
          font-size: 1.3vw;
          line-height: 2.5;
          letter-spacing: 4px;
        }
        .info_phone {
          font-size: 1.25vw;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #666;
        }
        a {
          text-decoration: none;
          .info_email {
            font-size: 1vw;
            line-height: 3;
            letter-spacing: normal;
            text-align: left;
            color: #e9af61;
          }
        }
      }
    }
  }
  .office_location_container {
    padding: 2% 7%;
    .office_location {
      font-size: 1.8vw;
      text-align: left;
      color: #000;
      line-height: 1.65;
      padding-bottom: 2%;
      .location {
        letter-spacing: 4.8px;
        line-height: 1.5;
        font-weight: 600;
      }
    }
  }
}
@media only screen and (min-width: 1700px) {
.contact_page_container{
  .user_input_section{
    .input_container{
      form{
        .message_input{
          height: 166px;
        }
      }
    }
    .button{
      margin-top: 2%;
    }
  }
}
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .contact_page_container {
    .user_input_section {
      .page_title {
        font-size: 20px;
      }
      .input_container {
        form {
          flex-direction: column;
          .personal_input {
            width: 100%;
            input {
              min-height: 11vw;
              font-size: 3.4vw;
              margin: 0 2% 2.5%;
            }
          }
          .message_input {
            margin-top: 5px;
            width: 100%;
            .textarea {
              padding: 4%;
              font-size: 3.6vw;
            }
          }
        }
      }
      .button {
        margin-top: 10%;
      }
    }
    .info_section {
      flex-direction: column;
      .info_image_container {
        width: 100%;
      }
      .info_text_container {
        width: 100%;
        flex-direction: column;
        padding: 5% 0px;
        .info_tile {
          width: 100%;
          padding: 2% 7%;
          .info_title {
            font-size: 6vw;
          }
          .info_phone {
            font-size: 5vw;
          }
          a {
            .info_email {
              font-size: 5vw;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .office_location_container {
      margin-bottom: 9%;
      padding: 0px 7%;
      .office_location {
        font-size: 5vw;
        padding: 12% 0px;
        margin-bottom: 1%;
        .location {
          letter-spacing: 4px;
        }
      }
    }
  }
}
