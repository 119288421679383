.wrapper {
  .grid_desktop {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    justify-content: center;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
    .column {
      -ms-flex: 30%; /* IE10 */
      flex: 30%;
      max-width: 30%;
      padding: 0 4px;
      img {
        margin-top: 8px;
        vertical-align: middle;
        width: 100%;
      }
    }
  }
  .grid_mobile {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    .grid_desktop {
      display: none;
    }
    .grid_mobile {
      display: -ms-flexbox; /* IE10 */
      display: flex;
      justify-content: center;
      -ms-flex-wrap: wrap; /* IE10 */
      flex-wrap: wrap;
      padding: 0 4px;
      .column {
        -ms-flex: 50%; /* IE10 */
        flex: 50%;
        max-width: 50%;
        padding: 0 4px;
        img {
          margin-top: 8px;
          vertical-align: middle;
          width: 100%;
        }
      }
    }
  }
}
