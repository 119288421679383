.more_technology_tile_container {
  padding: 3% 7% 5%;
  .technology_tiles_container {
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    justify-content: space-between;
    .tile {
      width: 24%;
      min-height: 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      img {
        width: 100%;
        height: auto;
      }
      .info {
        font-size: 1.3vw;
        padding: 7% 8%;
        height: 25vh;
        position: relative;
        color: #000;
        .description {
          padding-top: 10%;
          padding-bottom: 3%;
        }
        .redirect {
          font-size: 16px;
          padding: 3% 0%;
          position: absolute;
          width: 100%;
          bottom: 10%;
          color: #e9af61;
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .more_technology_tile_container {
    .technology_tiles_container {
      flex-wrap: wrap;
      .tile {
        width: 48%;
        margin-bottom: 5%;
        .info {
          font-size: 12px;
          height: 20vh;
          .redirect {
            font-size: 12px;
          }
        }
      }
    }
  }
}
