.title_container {
  background-color: #2c2c2c;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 9px;
  padding: 0 7%;
  padding-top: 3%;
  h1 {
    margin: 0;
    width: 441px;
    font-size: 1.8vw;
    font-weight: lighter;
    padding-bottom: 10px;
  }
  .title_underline {
    width: 441px;
    height: 12px;
    background-color: #e9af61;
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .title_container {
    h1 {
      font-size: 4.8vw;
      width: 100%;
    }
    .title_underline {
      width: 100%;
    }
  }
}
