.data_solutions_container {
  .paragraph_container {
    padding: 2% 7%;
  }
  .line_separator {
    width: 100%;
    height: 4px;
    background-color: #e9af61;
  }
  .data_stack_section {
    display: flex;
    padding: 5% 7%;
    flex-direction: column;
    justify-content: space-between;
    .description {
      display: flex;
      justify-content: space-between;
      .stack_section {
        width: 50%;
        .grey_line_separator {
          width: 40%;
          height: 2px;
          background-color: grey;
        }
        .stack_title {
          font-size: 31px;
          letter-spacing: 0.62px;
        }
      }
      .stack_img_wrapper {
        width: 50%;
        img {
          width: 90%;
        }
      }
    }
    .data_stack_section_mobile {
      display: none;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .data_solutions_container {
    .paragraph_container {
      margin-top: 5%;
    }
    .features_container {
      .tiles_container {
        margin-top: 10%;
        .feature_tile_container {
          margin: 3% 0px;
        }
      }
    }
    .data_stack_section {
      display: none;
    }
    .data_stack_section_mobile {
      display: block;
    }
  }
}
