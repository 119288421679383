.custom_button_container {
  button {
    background-color: #e9af61;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 4px;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 24vw;
    padding: 1% 0.5vw;
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .custom_button_container {
    display: flex;
    justify-content: center;
    button {
      width: 325px;
      font-size: 19px;
    }
  }
}
