.sass_offerings_tile_container {
  .content_container {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .text_info {
      width: 50%;
      position: absolute;
      left: 7%;
      padding-right: 7%;
      color: #000000;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      .title {
        font-size: 2.8vw;
        letter-spacing: 6.4px;
        text-align: left;
      }
      .text {
        padding-top: 5%;
        padding-right: 25%;
        font-size: 1.5vw;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
      }
      .button_container {
        padding-top: 5%;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .sass_offerings_tile_container {
    .content_container {
      .text_info {
        width: 100%;
        top: 10%;
        left: 4%;
        .title {
          font-size: 20px;
          letter-spacing: 2px;
        }
        .text {
          font-size: 16px;
        }
        .button_container {
          padding-top: 5%;
        }
      }
    }
  }
}
