.header_one_container {
  h1 {
    font-size: 1.8vw;
    font-weight: lighter;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 9px;
    text-align: left;
    color: #6e6e6e;
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .header_one_container {
    h1 {
      font-size: 22px;
      line-height: 1.7;
      letter-spacing: 6px;
    }
  }
}
