.generic_homepage_slides_container {
  .video_container {
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    height: 100vh;
    .player {
      overflow: hidden;
      position: relative;
      z-index: -1;
      video {
        position: absolute;
        object-fit: fill;
        min-width: 100%;
        height: 0;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .info_container {
      text-align: left;
      color: #ffffff;
      padding: 2% 7%;
      width: 45%;
      position: absolute;
      left: 1%;
      .title {
        font-size: 55px;
        line-height: 1.05;
        letter-spacing: 2.9px;
        padding-bottom: 5%;
      }
      .description {
        font-size: 24px;
        line-height: 1.21;
        padding-bottom: 5%;
      }
      .news_buttons_content {
        .button_spacer {
          padding-bottom: 3%;
        }
      }
    }
    .text_animation {
      width: 20vw;
      cursor: pointer;
      img {
        width: 20%;
        height: auto;
        position: absolute;
        bottom: 2%;
        left: 7%;
      }
    }
  }
}
@media only screen and (min-width: 741px) and (max-width: 1000px) {
  .generic_homepage_slides_container {
    .video_container {
      .info_container {
        padding: 2% 1% 2% 7%;
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 740px) {
  .generic_homepage_slides_container {
    .video_container {
      position: relative;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.65) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
      height: 100vh;
      .player {
        overflow: hidden;
        position: relative;
        z-index: -1;
        video {
          position: absolute;
          object-fit: fill;
          min-width: 100%;
          height: 0;
          -webkit-transform: translateX(-50%) translateY(-50%);
          -moz-transform: translateX(-50%) translateY(-50%);
          -ms-transform: translateX(-50%) translateY(-50%);
          -o-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          top: 50%;
          left: 50%;
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
        }
      }
      .info_container {
        width: 100%;
        .title {
          font-size: 35px;
        }
        .description {
          font-size: 20px;
        }
      }
      .text_animation {
        bottom: 3%;
        width: 100%;
        img {
          width: 50%;
        }
      }
    }
  }
}
