.creative_page_container {
  width: 100%;
  .intro_wrapper {
    width: 95%;
    padding: 30px 0;
    padding-left: 7%;
  }
  .capabilities_wrapper {
    .title_underline {
      width: 441px;
      height: 12px;
      background-color: #bf9058;
    }
    .capabilities_subtitle {
      width: 95%;
      padding-left: 7%;
      font-size: 55px;
      .subtitle_grey {
        color: #6e6e6e;
      }
      .subtitle_navy {
        color: #31465e;
      }
    }
  }
  .capabilities_intro_wrapper {
    width: 95%;
    display: flex;
    .showcase_column_left {
      width: 70%;
      p {
        padding-left: 11%;
        width: 85%;
        margin-bottom: 8%;
      }
    }
    .showcase_column_right {
      width: 25%;
    }
    .mobile_video_wrapper {
      display: none;
    }
  }
  .second_paragraph_wrapper {
    width: 95%;
    padding-left: 7%;
    margin-top: 5%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .creative_page_container {
    .intro_wrapper {
      margin-top: 11%;
    }
    .capabilities_wrapper {
      .subtitle_container {
        margin-top: 10%;
      }
      .capabilities_subtitle {
        font-size: 26px;
      }
      .capabilities_intro_wrapper {
        display: block;
        .showcase_column_left {
          width: 95%;
          p {
            width: 95%;
            padding-left: 8%;
          }
          .desktop_img {
            display: none;
          }
        }
        .showcase_column_right {
          width: 95%;
          .desktop_img {
            display: none;
          }
        }
      }
      .mobile_video_wrapper {
        display: block;
        img {
          width: 100%;
        }
      }
    }
    .second_paragraph_wrapper {
      margin-top: 15%;
    }
  }
}
