.news_tile_container {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  img {
    width: 100%;
    height: auto;
  }
  min-height: 100%;
  .description {
    font-size: 1.3vw;
    padding: 5% 7%;
    color: #303640;
    height: 32vh;
    position: relative;
    line-height: 1.25;
    .redirect {
      width: 100%;
      font-size: 16px;
      position: absolute;
      bottom: 20%;
      .date_published {
        color: #969696;
        font-size: 14px;
        padding-bottom: 5%;
      }
      color: #e9af61;
      .icon {
        margin-right: 2%;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .news_tile_container {
    .description {
      font-size: 12px;
      height: 27vh;
      position: relative;
      .redirect {
        width: 100%;
        font-size: 12px;
        position: absolute;
        bottom: 20%;
        .date_published {
          font-size: 10px;
        }
      }
    }
  }
}
