@mixin hamburger_menu_line {
  height: 2px;
  background-color: white;
  margin: 3px;
}

.navbar_container {
  background-color: #2c2c2c;
  padding: 2.5% 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .contellation_logo_container {
    width: 310px;
    img {
      width: 85%;
      height: auto;
    }
  }
  .menu_container {
    display: flex;
    cursor: pointer;
    .hamburger_menu {
      transform: scaleX(-1);
      padding: 2%;
      .big_line {
        width: 25px;
        @include hamburger_menu_line();
      }
      .medium_line {
        width: 15px;
        @include hamburger_menu_line();
      }
      .small_line {
        width: 7px;
        @include hamburger_menu_line();
      }
    }
    .menu_text {
      line-height: 1.22;
      letter-spacing: 3.96px;
      text-align: left;
      color: #ffffff;
      font-size: 18px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .navbar_container {
    .contellation_logo_container {
      width: 210px;
    }
  }
}
