.careers_page_container {
  .careers_content_wrapper {
    padding-top: 4% 0 0;
    .subtitle_wrapper {
      padding-left: 7%;
    }
  }
  .benefits_section {
    display: flex;
    background-color: #ececec;
    padding: 6% 10%;
    justify-content: space-between;
    .single_benefit {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .icon_wrapper {
        background-color: white;
        border-radius: 50%;
        width: 130px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        .benefit_icon {
          width: 60%;
        }
      }
      .benefit_title {
        font-size: 19px;
        font-weight: 600;
      }
    }
  }
  .job_posting_wrapper {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    justify-content: left;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 2% 7% 0;
    width: 100%;
    align-items: center;
    margin-bottom: 5%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .careers_page_container {
    .benefits_section {
      flex-direction: column;
      padding: 8%;
      .single_benefit {
        flex-direction: row;
        justify-content: end;
        margin: 4%;
        .icon_wrapper {
          height: 70px;
          width: 70px;
          margin-right: 8%;
        }
      }
    }
    .job_posting_wrapper {
      flex-direction: column;
    }
  }
}
