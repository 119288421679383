.grid_horizontal_wrapper {
  .images_grid {
    display: flex;
    justify-content: center;
    margin-top: 7%;
    background: linear-gradient(white, #f4f4f4);
    margin-bottom: 4%;
    img {
      width: 100%;
    }
  }
}
