* {
  box-sizing: border-box;
}

body {
  font-family: 'ProximaNova';
}

a {
  text-decoration: none;
}
