.alexia_page_container {
  .automation_section {
    padding: 4% 7%;
    .automation_image_section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .description {
        width: 25%;
        font-family: 'PlayfairDisplay';
        font-size: 40px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
      .image_container {
        width: 65%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .automation_graphics_container {
    background-color: #212f3f;
    padding: 0 7% 3% 7%;
    .above_line {
      width: 441px;
      height: 12px;
      background-color: #bf9058;
    }
    .header_container {
      margin: 2% 0 5%;
    }
    .automation_paragraph {
      width: 50%;
    }
    .graphics_section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4% 5% 4% 0;
      .graphic_container {
        width: 30%;
        padding: 2%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .alexia_page_container {
    .automation_section {
      .automation_image_section {
        display: block;
        .description {
          width: 100%;
          font-size: 24px;
          text-align: center;
        }
        .image_container {
          width: 100%;
          padding-top: 15%;
        }
      }
    }
    .automation_graphics_container {
      .above_line {
        width: 100%;
      }
      .automation_paragraph {
        width: 100%;
      }
      .graphics_section {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10%;
        padding-bottom: 5%;
        .graphic_container {
          width: 100%;
          padding: 7% 2%;
        }
      }
    }
  }
}
