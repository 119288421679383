.job_posting_tile {
  width: 33%;
  padding: 3%;
  .job_title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10%;
  }
  .link_wrapper {
    display: flex;
    align-items: center;
    .icon {
      color: #6e6e6e;
      margin-right: 3%;
    }
    a {
      text-decoration: underline;
      color: #e9af61;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.88;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .job_posting_tile {
    width: 100%;
  }
}
