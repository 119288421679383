@mixin years_section {
  font-family: 'PlayfairDisplay';
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.4;
  letter-spacing: -0.75px;
  text-align: right;
}

.about_page_container {
  .about_page_content {
    .our_approach_section {
      display: flex;
      .text {
        padding-left: 7%;
        width: 50%;
        padding-top: 3%;
        padding-right: 5%;
      }
      .image_container {
        width: 50%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .image_collage_section {
      display: flex;
      margin-top: -5px;
      .main_image_container {
        width: 57.8%;
        img {
          width: 100%;
          height: auto;
        }
      }
      .sub_image_container {
        width: 42.2%;
        img {
          width: 100%;
          height: auto;
        }
        .bottom_images {
          width: 50%;
          display: flex;
          margin-top: -5px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .reduce_margin {
      margin-top: -5px;
    }
    .line_separator {
      width: 100%;
      height: 14px;
      background-color: #f4f4f4;
    }
    .history_section {
      display: flex;
      padding-left: 7%;
      align-items: center;
      padding-top: 2%;
      .years_section {
        text-align: right;
        @include years_section();
        padding-right: 1%;
        .highlighted_year {
          width: 181px;
          height: 64px;
          background-color: #e9af61;
          color: #ffffff;
          font-weight: bold;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 10%;
        }
        .years {
          padding-right: 10%;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .history_text_container {
        width: 100%;
        .image_container {
          position: relative;
          text-align: left;
          background-image: linear-gradient(
            to bottom,
            rgba(82, 82, 82, 0),
            #101010 121%
          );
          img {
            width: 100%;
            height: auto;
            position: relative;
            z-index: -1;
          }
        }
        .image_caption {
          color: white;
          position: absolute;
          bottom: 5px;
          left: 16px;
          font-size: 20px;
          line-height: 1.2;
          padding: 5%;
        }
      }
    }
    .culture_section {
      padding: 7% 7%;
      padding-bottom: 2%;
      display: flex;
      align-items: flex-start;
      .culture_text {
        width: 30%;
      }
      .culture_image_collage {
        width: 100%;
        .image_row {
          display: flex;
          margin-left: 15%;
          .image_container {
            width: 25%;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    .culture_employess_container {
      padding: 2% 7%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .clients_and_partnerships_section {
      background-color: #2c2c2c;
      padding: 2% 7%;
      width: 100%;
      .clients_container {
        display: flex;
        margin: 6% 0px;
        .text {
          width: 35%;
        }
        .logos {
          width: 65%;
          .logos_row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            .logo_container {
              width: 22%;
              padding: 1%;
              margin: 0px 1%;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
    .leadership_section {
      padding: 3% 7%;
      .title {
        padding-bottom: 3%;
      }
      .leadership_container {
        display: flex;
        flex-wrap: wrap;
        .leader {
          width: 22%;
          margin-right: 3%;
          margin-bottom: 3%;
        }
      }
    }
    .team_section {
      background-color: #f4f4f4;
      padding: 2% 7%;
      .title {
        padding-bottom: 3%;
      }
      .team_container {
        display: flex;
        flex-wrap: wrap;
        .employee {
          width: 15%;
          margin-right: 1%;
          margin-bottom: 1%;
          z-index: 1;
        }
      }
    }
    .careers_section {
      display: flex;
      .careers_info {
        width: 46.4%;
        .careers_text {
          padding: 8% 14%;
          padding-right: 32%;
          min-height: 50%;
        }
        .under_career_image_container {
          height: 49.5%;
          width: 100%;
          overflow-y: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .careers_image_container {
        width: 53.6%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .about_page_container {
    .about_page_content {
      .our_approach_section {
        flex-direction: column;
        .text {
          padding-left: 7%;
          padding-right: 7%;
          width: 100%;
        }
        .image_container {
          width: 100%;
        }
      }
      .image_collage_section {
        .main_image_container {
          margin-top: 10px;
          width: 100%;
        }
      }
      .line_separator {
        width: 100%;
        height: 14px;
        background-color: #f4f4f4;
      }
      .history_section {
        flex-direction: column;
        padding-right: 7%;
        .years_section {
          display: flex;
          align-items: center;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.33;
          padding-right: 1%;
          width: 100%;
          .highlighted_year {
            width: 20%;
            height: 35px;
            background-color: #bf9058;
            color: #ffffff;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3%;
            margin-right: 2px;
          }
          .years {
            width: 20%;
            height: 35px;
            padding: 3%;
            background-color: #e8e8e8;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2px;
          }
        }
        .history_text_container {
          width: 100%;
          .image_container {
            background-image: none;
            margin-top: 10px;
            img {
              z-index: 0;
            }
          }
          .image_caption {
            color: #000000;
            position: relative;
            left: 0;
            font-size: 20px;
            line-height: 1.2;
            padding: 5% 0;
          }
        }
      }
      .culture_section {
        flex-direction: column;
        .culture_text {
          width: 100%;
        }
        .culture_image_collage {
          width: 100%;
          .image_row {
            margin-left: 0;
            .image_container {
              width: 33%;
            }
          }
        }
      }
      .clients_and_partnerships_section {
        padding: 5% 7%;
        .clients_container {
          flex-direction: column;
          .text {
            width: 100%;
            .header_one_container {
              h1 {
                line-height: 0.8;
                font-size: 19px;
              }
            }
          }
          .logos {
            width: 100%;
            .logos_row {
              padding: 0;
              .logo_container {
                width: 30%;
                padding: 0;
                img {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
        }
      }
      .leadership_section {
        padding: 3% 7%;
        .title {
          padding-bottom: 3%;
        }
        .leadership_container {
          display: flex;
          flex-wrap: wrap;
          .leader {
            width: 45%;
            margin-right: 3%;
            margin-bottom: 3%;
          }
        }
      }
      .team_section {
        .team_container {
          .employee {
            width: 24%;
          }
        }
      }
      .careers_section {
        flex-direction: column;
        .careers_info {
          width: 100%;
          .careers_text {
            padding: 8% 7%;
            padding-right: 7%;
            .careers_button {
              button {
                padding: 1% 6%;
                font-size: 18px;
              }
            }
          }
        }
        .careers_image_container {
          width: 100%;
        }
      }
    }
  }
}
