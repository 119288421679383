.paragraph_style {
  font-size: 1.8vw;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .paragraph_style {
    font-size: 20px;
  }
}
