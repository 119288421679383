.navigation_menu_section {
  .navigation_menu_container {
    color: white;
    background-color: #000000;
    z-index: 20;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    transition: transform 1s cubic-bezier(0, 0.52, 0, 1);
    .menu_header {
      padding: 3% 7%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .constellation_logo_container {
        width: 7%;
        img {
          width: 100%;
          height: auto;
        }
      }
      .go_back_button {
        &:hover {
          cursor: pointer;
        }
        .back_button_container img {
          width: 100%;
          height: auto;
        }
      }
    }
    .menu_content_container {
      padding: 1% 25%;
      font-family: 'PlayfairDisplay';
      font-size: 38px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.88;
      letter-spacing: -1px;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .field_text {
        margin: 3% 10%;
        a {
          text-decoration: none;
          color: #6e6e6e;
          &:hover {
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }

  .hide {
    transform: translate3d(100vw, 0, 0);
  }

  .display {
    transform: translate3d(0vw, 0, 0);
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .navigation_menu_section {
    .navigation_menu_container {
      .menu_header {
        margin-top: 6%;
        .constellation_logo_container {
          width: 13%;
        }
        .go_back_button {
          .back_button_container {
            img {
              width: 70%;
            }
          }
        }
      }
      .menu_content_container {
        font-size: 36px;
        padding: 0px;
        margin-top: 15%;
        .field_text {
          margin: 4% 10%;
        }
      }
    }
  }
}
