.features_container {
  background-color: #f5f5f5;
  padding: 0 7% 7%;
  .above_line {
    width: 441px;
    height: 12px;
    background-color: #e9af61;
  }
  .header_container {
    margin: 2% 0 6%;
  }
  .tiles_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .feature_tile_container {
      width: 33%;
      padding-bottom: 1%;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .features_container {
    .above_line {
      width: 100%;
    }
    .tiles_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .feature_tile_container {
        width: 100%;
        padding-bottom: 1%;
      }
    }
  }
}
