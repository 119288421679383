.causes_page_container {
  width: 100%;
  .intro_wrapper {
    width: 95%;
    padding: 30px 0px;
    padding-left: 7%;
  }
  .img_wrapper {
    display: flex;
    justify-content: center;
    .intro_img_mobile {
      display: none;
    }
    img {
      width: 86%;
    }
  }
  .company_wrapper {
    margin: 4% 0px;
    display: flex;
    width: 95%;
    justify-content: space-around;
    align-items: center;
    padding-left: 5%;

    .logo_wrapper {
      width: 40%;
      height: 301px;
      box-shadow: 6px 6px 26px 0 rgba(0, 0, 0, 0.16);
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      .logo_img {
        display: flex;
        justify-content: center;
        img {
          width: 85%;
        }
      }
    }
    .text {
      width: 50%;
      .title_wrapper {
        display: flex;
        align-items: center;
        .gold_rectangle {
          width: 10px;
          height: 20px;
          background-color: #e9af61;
          margin-right: 20px;
        }
        .company_title {
          margin: 10px 0px;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-size: 33px;
        }
      }
      p {
        margin-top: 2%;
      }
    }
  }
  .gray_line_divider {
    width: 95%;
    height: 7px;
    background-color: #efefef;
    margin: 0 auto;
  }
  .regular_paragraph_content {
    width: 95%;
    padding: 30px 0px;
    padding-left: 7%;
    .learn_more {
      font-size: 1.8vw;
      font-weight: lighter;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      a {
        span {
          text-decoration: underline;
          color: #e9af61;
        }
      }
    }
    .images_grid {
      display: flex;
      justify-content: center;
      margin-top: 7%;
      background: linear-gradient(white, #f4f4f4);
      margin-bottom: 4%;
      img {
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 740px) {
  .causes_page_container {
    .img_wrapper {
      width: 100%;
      .intro_img_desktop {
        display: none;
      }
      .intro_img_mobile {
        display: block;
        width: 95%;
        height: 270px;
      }
    }
    .company_wrapper {
      flex-direction: column;
      margin-top: 10%;
      .logo_wrapper {
        width: 95%;
        height: 100px;
        margin: 5% 0px;
        .logo_img {
          width: 100%;
          height: 34px;
          img {
            width: 93%;
          }
        }
      }
      .text {
        width: 95%;
        margin-top: 4%;
        .title_wrapper {
          .gold_rectangle {
            width: 20px;
            margin-right: 15px;
          }
          .company_title {
            font-size: 26px;
          }
        }
      }
    }
    .regular_paragraph_content {
      .learn_more {
        font-size: 20px;
      }
    }
  }
}
