.footer_section {
  .let_work_together_section {
    margin-top: -5px;
    background-image: linear-gradient(to bottom, #31465e -61%, #151e28 135%);
    padding: 8% 7%;
    height: 60vh;
    .text {
      font-size: 40px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.5;
      letter-spacing: -1px;
      text-align: left;
      color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 5.6px;
    }
  }
  .add_padding {
    height: 35vh;
  }
  .lets_work_together_flex {
    margin-top: -5px;
    background-image: linear-gradient(to bottom, #31465e -61%, #151e28 135%);
    padding: 4% 7%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .text {
      font-size: 40px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.5;
      letter-spacing: -1px;
      text-align: left;
      color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 5.6px;
    }
  }
  .footer_menu_section {
    background-color: #1a1a1a;
    display: flex;
    padding-right: 7%;
    width: 100%;
    height: 100%;
    .constellation_3d_logo_container {
      width: 31%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .menu_text_container {
      display: flex;
      justify-content: space-between;
      width: 63%;
      .individual_menu {
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding: 3% 5%;
        .title {
          font-size: 22px;
          line-height: 2.5;
          letter-spacing: 4px;
        }
        .text {
          padding: 3% 0;
          font-size: 16px;
          letter-spacing: normal;
          width: 13vw;
          a {
            text-decoration: none;
            color: #ffffff;
          }
        }
        .social_media_icons {
          display: flex;
          padding-top: 5%;
          .icon_container {
            margin-right: 5%;
            a img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    .constellation_2d_logo_container {
      width: 6%;
      padding-top: 3%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
@media only screen and (min-width: 741px) and (max-width: 1000px) {
  .footer_section {
    .footer_menu_section {
      .menu_text_container {
        .individual_menu {
          padding: 3%;
        }
      }
      .constellation_3d_logo_container {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          flex-shrink: 0;
          min-width: 100%;
          min-height: 100%;
        }
      }
      .menu_text_container {
        .individual_menu {
          .title {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .footer_section {
    .let_work_together_section {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 15% 7% 8% 7%;
      margin-top : 0px;
      .text {
        font-size: 26px;
        width: 100%;
        line-height: 1.6;
        margin-top: 11%;
      }
      .work_button {
        width: 100%;
        margin-top: 7%;
        button {
          padding: 1% 20%;
        }
      }
    }
    .lets_work_together_flex {
      flex-direction: column;
      padding: 15% 7%;
      .text {
        line-height: 1.6;
        font-size: 26px;
        width: 100%;
        margin-bottom: 10%;
      }
      .work_button {
        width: 100%;
        button {
          padding: 1% 23%;
        }
      }
    }
    .footer_menu_section {
      flex-direction: column;
      height: 100%;
      .menu_text_container {
        width: 100%;
        flex-wrap: wrap;
        .individual_menu {
          .title {
            font-size: 16px;
          }
          .text {
            font-size: 12px;
          }
        }
        .constellation_2d_logo_container {
          width: 15%;
        }
      }
    }
  }
}
