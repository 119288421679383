.technology_hero_footer_section {
  .hero_image_container {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .footer_hero_info {
      position: absolute;
      border: solid 20px rgba(49, 70, 94, 0.25);
      width: 40%;
      padding: 1% 3%;
      left: 7%;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .technology_hero_footer_section {
    .hero_image_container {
      display: flex;
      justify-content: center;
      align-items: center;
      .footer_hero_info {
        width: 90%;
        padding: 3% 7%;
        top: 12%;
      }
    }
  }
}
