.videos_wrapper {
  margin-top: 4%;
  .tab a button {
    padding: 1% 5%;
    margin-left: 7%;
    background-color: #e9af61;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 4px;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .single_video {
    margin: 5% 0px 0px 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .videos_wrapper {
    .single_video {
      margin: 20% 0px;
    }
    .tab a button {
      margin-left: 0%;
    }
  }
}
