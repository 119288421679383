.features_tile_container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 10%;
  min-height: 100%;
  .icon_container {
    padding: 20% 8% 10% 8%;
    width: 80%;
    img {
      width: 100%;
    }
  }
  .description {
    padding: 15% 0;
    font-size: 1.5vw;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .features_tile_container {
    display: flex;
    flex-direction: row;
    padding: 2% 5%;
    .icon_container {
      padding: 20% 8% 10% 3%;
      width: 50%;
      img {
        padding: 3%;
      }
    }
    .description {
      width: 50%;
      padding: 15% 0;
      font-size: 16px;
      text-align: left;
    }
  }
}
