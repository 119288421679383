.launchpad_page_container {
  .paragraph_container {
    padding: 4% 7%;
  }
  .launchpad_info_section {
    background-color: #efefef;
    padding: 4% 7% 10%;
    .button_container {
      padding: 1% 0 2% 0;
      margin-bottom: 5%;
    }
    .launchpad_block_one {
      display: flex;
      .ad_on_demand {
        background: linear-gradient(216.46deg, #2b4660 0%, #131e29 100%);
        color: #ffffff;
        padding: 3%;
        width: 35%;
        .title {
          padding-right: 20%;
        }
        .image_container {
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .uploaded_content {
        background: linear-gradient(90.36deg, #ffffff 0%, #b7b7b7 100%);
        color: #151d28;
        padding: 3%;
        display: flex;
        align-items: flex-start;
        width: 65%;
        .title {
          padding-right: 70%;
        }
        .description {
          padding-right: 30%;
        }
        .image_container {
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .launchpad_block_two {
      display: flex;
      .paid_and_audience_container {
        width: 66.6%;
        .paid_organic_container {
          display: flex;
          width: 100%;
          background: linear-gradient(90.36deg, #ffffff 0%, #b7b7b7 100%);
          color: #151d28;
          .info {
            padding: 5%;
            width: 40%;
          }
          .image_container {
            width: 60%;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .advanced_audience_targeting_container {
          background: linear-gradient(216.46deg, #2b4660 0%, #131e29 100%);
          color: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5%;
          margin-top: -5px;
          .info {
            padding: 1%;
            width: 40%;
          }
          .image_container {
            width: 50%;
            img {
              width: 100%;
              height: auto;
              padding: 7%;
            }
          }
        }
      }
      .easy_to_use_container {
        width: 33.4%;
        background: linear-gradient(47deg, #2d2d2d 2.35%, #575757 97.79%);
        color: #ffffff;
        padding: 2% 5%;
        .title {
          padding-right: 50%;
        }
        .description {
          padding-bottom: 10%;
        }
        .image_container {
          margin: 0 auto;
          width: 90%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .launchpad_block_three {
      display: flex;
      .hybrid_approach_container {
        width: 33.4%;
        padding: 2% 5%;
        background: linear-gradient(90.36deg, #ffffff 0%, #b7b7b7 100%);
        color: #151d28;
      }
      .creative_built_and_shared_content_container {
        width: 66.6%;
        .creative_and_built_container {
          display: flex;
          .creative_library_container {
            padding: 2% 5%;
            width: 50%;
            background: linear-gradient(47deg, #2d2d2d 2.35%, #575757 97.79%);
            color: #ffffff;
          }
          .built_in_disclaimers_container {
            padding: 2% 5%;
            width: 50%;
            background: linear-gradient(216.46deg, #2b4660 0%, #131e29 100%);
            color: #ffffff;
          }
        }
        .shared_content_creation_container {
          padding: 10%;
          background: linear-gradient(90.36deg, #ffffff 0%, #b7b7b7 100%);
          color: #151d28;
          display: flex;
          justify-content: space-between;
          .info {
            width: 45%;
          }
          .image_container {
            width: 50%;
            padding: 3%;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 740px) {
  .launchpad_page_container {
    .launchpad_info_section {
      padding: 2% 0%;
      .paragprah {
        padding-left: 7%;
      }
      .button_container {
        padding-left: 7%;
      }
      .launchpad_block_one {
        flex-direction: column;
        padding-top: 10%;
        .ad_on_demand {
          width: 100%;
          padding-left: 7%;
          .title {
            padding-right: 20%;
          }
          .image_container {
            width: 100%;
          }
        }
        .uploaded_content {
          flex-direction: column;
          padding-left: 7%;
          width: 100%;
          .info{
            margin-bottom: 6%;
            .title {
              padding-right: 70%;
            }
            .description {
              padding-right: 30%;
            }
            .image_container {
              width: 100%;
            }
          }
        }
      }
      .launchpad_block_two {
        flex-direction: column;
        .paid_and_audience_container {
          width: 100%;
          .paid_organic_container {
            flex-direction: column;
            padding-left: 5%;
            color: #ffffff;
            background: linear-gradient(216.46deg, #2b4660 0%, #131e29 100%);
            .info {
              width: 100%;
      
            }
            .image_container {
              width: 100%;
            }
          }
          .advanced_audience_targeting_container {
            flex-direction: column;
            padding-left: 7%;
            background: linear-gradient(90.36deg, #ffffff 0%, #b7b7b7 100%);
            color: #151d28;
            .info {
              width: 100%;
            }
            .image_container {
              width: 100%;
            }
          }
        }
        .easy_to_use_container {
          width: 100%;
          padding-left: 7%;
          .title {
            padding-right: 50%;
          }
          .description {
            padding-bottom: 10%;
          }
        }
      }
      .launchpad_block_three {
        flex-direction: column;
        .hybrid_approach_container {
          width: 100%;
          padding-left: 7%;
          padding-top: 7%;
          .image_container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .creative_built_and_shared_content_container {
          width: 100%;
          .creative_and_built_container {
            flex-direction: column;
            .creative_library_container {
              width: 100%;
              padding-left: 7%;
            }
            .built_in_disclaimers_container {
              width: 100%;
              padding-left: 7%;
            }
          }
          .shared_content_creation_container {
            flex-direction: column;
            .info {
              width: 100%;
            }
            .image_container {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
